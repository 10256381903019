
import { defineComponent } from "vue";
import Container from "../../components/Structure/Container.vue";
import {
  Header,
  SingleCheckbox,
  QuestionWrapper,
} from "../../components/Questions";
import { useRouter } from "vue-router";

export default defineComponent({
  components: {
    Container,
    Header,
    SingleCheckbox,
    QuestionWrapper,
  },
  setup() {
    const questionNumber = 3;
    const router = useRouter();

    const title = "Are you currently breastfeeding?";
    const options = [
      {
        label: "Yes",
        value: "Yes",
      },
      {
        label: "No",
        value: "No",
      },
    ];

    const onAnswered = () => {
      router.push(String(questionNumber + 1));
    };

    return {
      options,
      onAnswered,
      questionNumber,
      title,
    };
  },
});
